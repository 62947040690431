import { h, resolveComponent } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import { usePageStore } from '@/stores/page'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/page-home.vue'),
    meta: { title: 'Home' },
  },
  {
    path: '/program/:id?',
    name: 'program',
    component: () => import('@/views/program/page-program.vue'),
    meta: { title: 'Program' },
  },
  {
    path: '/donasi/:programId',
    name: 'donate',
    component: () => import('@/views/donate/page-donate.vue'),
    meta: { title: 'Terima Kasih Telah Berdonasi' },
  },
  {
    path: '/terima-kasih/:donationId',
    name: 'thank you',
    component: () => import('@/views/donate/page-thank-you.vue'),
    meta: { title: 'Donasi' },
  },
  {
    path: '/gagal',
    name: 'failed',
    component: () => import('@/views/donate/page-failed.vue'),
    meta: { title: 'Pembayaran Gagal' },
  },
  {
    path: '/event',
    component: { render: () => h(resolveComponent('router-view')) },
    children: [
      {
        path: '',
        name: 'event list',
        component: () => import('@/views/event/page-event.vue'),
        meta: { title: 'Event' },
      },
      {
        path: ':id/:title?',
        name: 'event detail',
        props: true,
        component: () => import('@/views/event/page-event-detail.vue'),
        meta: { title: 'Event' },
      },
    ],
  },
  {
    path: '/mitra',
    name: 'partner',
    component: () => import('@/views/partner/page-partner.vue'),
    meta: { title: 'Mitra' },
  },
  {
    path: '/galeri',
    name: 'gallery',
    component: () => import('@/views/gallery/page-gallery.vue'),
    meta: { title: 'Galeri' },
  },
  {
    path: '/about-us',
    name: 'about',
    component: () => import('@/views/about/page-about.vue'),
    meta: { title: 'About Us' },
  },
  {
    path: '/report',
    name: 'Report',
    component: () => import('@/views/report/page-report.vue'),
    meta: { title: 'Report' },
  },
  {
    path: '/report/detail',
    name: 'Report Detail',
    component: () => import('@/views/report/detail/page-detail.vue'),
    meta: { title: 'Report Detail' },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/errors/page-503.vue'),
    meta: { title: 'Maintenance' },
  },
  {
    path: '/:pathMatch(.*)*',
    component: () => import('@/views/errors/page-404.vue'),
    meta: { title: '404' },
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  scrollBehavior (to, from, savedPosition) {
    return { top: 0 }
  },
})

router.beforeEach((to, from, next) => {
  const page = usePageStore()

  if (!to.meta.title) {
    throw new Error('Page must have a title.')
  }

  page.setTitle(to.meta.title)

  next()
})

declare module 'vue-router' {
  interface RouteMeta {
    title: string,
  }
}

export default router
