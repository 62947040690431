import { defineStore } from 'pinia'
import { useAppStore } from '@/stores/app'

export const usePageStore = defineStore('Page', {
  state: () => ({
    title: '',
  }),
  actions: {
    setTitle (title: string) {
      const app = useAppStore()
      this.title = title
      document.title = `${title} | ${app.COMPANY_NAME}`
    },
  },
})